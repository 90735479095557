import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

function Vulnerabilitypolicy() {
  return (
    <div className="container">
      <div className="fnt-16">
        <h1 className="my-5 py-5 text-center fw-bold">Vulnerability Policy</h1>
        <div className="mb-4">At Dripfunnel we take the security of our products and services very seriously – so the feedback widget from security researchers is appreciated. It helps us safeguard our services and protect our customers and their data.</div>
        <div className="mb-4">We operate a policy of responsible disclosure for reporting security vulnerabilities. If you are involved with security research, please find the ins and outs of it here:</div>
        <div className="mb-4 fw-bold">How to report a suspected security vulnerability</div>
        <div className="mb-4">If you believe you’ve found a potential vulnerability, please use the vulnerability report form and give us as much detail about it as possible.</div>
        <div className="mb-4">Please don’t make any information about any vulnerabilities public, or do anything else that might put our customers’ data or our intellectual property at risk. And do not degrade our systems.</div>
        <div className="mb-4 fw-bold">What actions will we take?</div>
        <div className="mb-4">We’ll acknowledge your submission and review the reported issue. If you’re right and there is an issue, we’ll give you an estimate for how long it will take to sort out.</div>
        <div className="mb-4 fw-bold">Activity that we don’t allow:</div>
        <div className="mb-4">We don’t allow any activity that might interfere with customers using our services, or any activity that might result in the modification, deletion or unauthorized disclosure of our intellectual property or personal customer data. With that in mind, these are some of the specific things we don’t allow:</div>
        <div>
          <ol>
            <li className="mb-4">Public disclosure of personal, proprietary or financial information</li>
            <li className="mb-4">The modification or deletion of data that isn’t yours</li>
            <li className="mb-4">The modification or deletion of data that isn’t yours</li>
            <li className="mb-4">Interruption, degradation or outage to services (like Denial of Service attacks)</li>
            <li className="mb-4">Spamming / social engineering / phishing attacks</li>
            <li className="mb-4">Physical exploits and/or attacks on our infrastructure</li>
            <li className="mb-4">Local network-based attacks such as DNS poisoning or ARP spoofing</li>
          </ol>
        </div>
        <div className="mb-4 fw-bold">Vulnerability submissions that are out of scope of our responsible disclosure policy:</div>
        <div>
          <ol>
            <li className="mb-4">Accessible non-sensitive files and directories (e.g. README.txt, robots.txt, etc.)</li>
            <li className="mb-4">Fingerprinting / banner / version disclosure of common / public services</li>
            <li className="mb-5">Username / email enumeration by brute forcing or by inference of certain error messages – except in exceptional circumstances (e.g. the ability to enumerate email addresses by incrementing a variable)</li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default Vulnerabilitypolicy;
